@import "../../global.scss";

li{
    font-size: 15px;
    margin-right: 50px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;

    @include mobile("200px","1000px"){
        margin-right: 0;
    }

    &.active{
        background-color: $mainColor;
        color: white;
    }
}