@import "../../global.scss";

.contact{
    background-color: white;
    display: flex;
    @include mobile("200px","992px"){
        flex-direction: column;
    }

    .left{
        flex: 1;
        overflow: hidden;
        

        img{
            height: 100%;
        }
    }
    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        

        h2{
            font-size: 30px;
            overflow: hidden;

        }
        form{
            width: 70%;
            height: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;

            @include mobile("200px","992px"){
                margin-top: 20px;
                width: 90%;
            }
           

            input{
                width: 300px;
                height: 30px;
                font-size: 14px;

                @include mobile("380px","576px"){
                    width: 200px;
                    height: 30px;
                }
                @include mobile("200px","379px"){
                    width: 180px;
                    height: 30px;
                    margin-top: 10px;
                }
            }
            textarea{
                width: 300px;
                height: 200px;
                font-size: 14px;

                @include mobile("380px","576px"){
                    width: 200px;
                    height: 150px;
                    
                }
                @include mobile("200px","379px"){
                    width: 180px;
                    height: 100px;
                    margin-top: 10px;
                }
            }
            button{
                width: 150px;
                height: 30px;
                color: white;
                background-color: crimson;
                border: none;
                border-radius: 10px;
                font-weight: 500;
                cursor: pointer;
                @include mobile("380px","992px"){
                    margin-top: 20px;
                }
                @include mobile("200px","379px"){
                    margin-top: 10px;
                }

                &:focus{
                    outline: none;
                }
            }
            span{
                color: green;
            }
        }
    }
}