@import "../../global.scss";

.intro{
    background-color: white;
    display: flex;

    @include mobile("200px","992px"){
        flex-direction: column;
        align-items: center;
    }

    .left{
        flex: 0.5;
        overflow: hidden;
        
        .imgContainer{
            width: 700px;
            height: 700px;
            background-color: crimson;
            border-radius: 50%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            float: right;

            @include mobile("200px","576px"){
                align-items: flex-end;
                width: 300px;
                height: 300px;
            }

            @include mobile("577px","768px"){
                align-items: flex-end;
                width: 400px;
                height: 400px;
            }

            @include mobile("769px","992px"){
                align-items: flex-end;
                width: 500px;
                height: 500px;
            }
            @include mobile("993px","1200px"){
                align-items: flex-end;
                width: 600px;
                height: 600px;
            }
            img{
                height: 90%;
               
            }
        }
    }
    .right{
        flex: 0.5;
        position: relative;

        .wrapper{
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile("200px","992px"){
                padding-left: 0;
                align-items: center;
                height: auto;
            }
            

            h1{
                font-size: 60px;
                margin: 10px 0;
                @include mobile("769px","992px"){
                    font-size: 40px;
                }
                @include mobile("200px","576px"){
                    font-size: 30px;
                }
            }
            h2{
                font-size: 35px;
                @include mobile("200px","576px"){
                    font-size: 25px;
                }
            }
            h3{
                font-size: 30px;
                @include mobile("769px","992px"){
                    font-size: 20px;
                }
                @include mobile("200px","576px"){
                    font-size: 19px;
                }


                span{
                    font-size: inherit;
                    color: crimson;
                }

                .ityped-cursor{
                    animation: blink 1s infinite;
                }

                @keyframes blink {
                    50%{
                        opacity: 1;
                    }
                    100%{
                        opacity: 0;
                    }
                }
            }
        }
        .contact-info{
            width: 100%;
            height: 50%;
            display: none;
            flex-direction: column;
            justify-content: center;
            color: $mainColor;

            @include mobile("200px","992px"){
                display: flex;
            }
            .itemContainer{
                display: flex;
                align-items: center;
                margin-left: 30px;

                .icon{
                    font-size: 18px;
                    margin-right: 5px;
                }

                span{
                    font-size: 15px;
                    font-weight: 400;
                }
            }
        }
        a{
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);

            img{
                width: 30px;
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink{
                100%{
                    opacity: 0.1;
                }
                50%{
                    opacity: 1;
                }
                0%{
                    opacity: 0.1;
                }
                
            }
        }
    }
}