@import "../../global.scss";

.portfolio{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    h1{
        font-size: 50px;

        @include mobile("200px","992px"){
            font-size: 30px;
            overflow: hidden;
        }
    }

    ul{
        margin: 10px;
        padding: 0;
        list-style-type: none;
        display: flex;

    
        @include mobile("200px","992px"){
            margin: 20px 0;
            width: 90%;
            flex-wrap: wrap;
            justify-content: space-around;

            li{
                display: flex;
                justify-content: center;
                margin-right: 0;
            }
        }
    }
    
    .container{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @include mobile("200px","1000px"){
            width: 100%;
            margin-top: 30px;
        }

        .item{
            width: 250px;
            height: 170px;
            border-radius: 20px;
            border: 1px solid rgb(240, 239, 239);
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            position: relative;
            transition: all 0.5s ease;
            cursor: pointer;

            @include mobile("769px","992px"){
                width: 280px;
                height: 200px;
            }
            @include mobile("577px","768px"){
                width: 240px;
                height: 180px;
            }
            @include mobile("380px","576px"){
                width: 170px;
                height: 115px;
                margin: 10px 5px;
            }
            @include mobile("200px","379px"){
                width: 140px;
                height: 100px;
                margin: 5px 5px;
            }

            h3{
                position: absolute;
                font-size: 20px;
                text-align: center;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }

            &:hover{
                background-color: $mainColor;
                img{
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }
    }
    .bottom{
        width: 100%;
        display: flex;
        justify-content: center;
        height: 50px;
        

        a{
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);

            img{
                width: 30px;
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink{
                100%{
                    opacity: 0.1;
                }
                50%{
                    opacity: 1;
                }
                0%{
                    opacity: 0.1;
                }
                
            }
        }
    }
}