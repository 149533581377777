@import "../../global.scss";

.testimonials{
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;

    h1{
        font-size: 50px;

        @include mobile("200px","992px"){
            font-size: 30px;
            overflow: hidden;
        }
    }

    .container{
        width: 100%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile("200px","1000px"){
            margin-top: 20px;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
        }

        .card{
            width: 250px;
            height: 70%;
            border-radius: 10px;
            box-shadow: 0px 0px 15px -8px black;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 20px;
            transition: all 0.8s ease;

            @include mobile("769px","992px"){
                height: 240px;
            }
            @include mobile("577px","768px"){
                height: 220px;
            }
            @include mobile("380px","576px"){
                height: 190px;
                padding: 15px;
            }
            @include mobile("200px","379px"){
                height: 120px;
                padding: 15px;
            }

            &.featured{
                width: 300px;
                height: 75%;
                margin: 0 30px;
                @include mobile("769px","992px"){
                    height: 250px;
                    margin: 1px;
                }
                @include mobile("577px","768px"){
                    height: 235px;
                    margin: 1px;
                }
                @include mobile("380px","576px"){
                    height: 200px;
                    width: 260px;
                    margin: 1px;
                }
                @include mobile("200px","379px"){
                    height: 130px;
                    width: 260px;
                    margin: 1px;
                }
            }

            &:hover{
                transform: scale(1.1);
            }
            .top{
                display: flex;
                align-items: center;
                justify-content: center;

                img{
                    &.left,&.right{
                        height: 25px;
                        @include mobile("200px","576px"){
                            height: 22px;
                        }
                    }
                    &.user{
                        height: 60px;
                        width: 60px;
                        border-radius: 50%;
                        object-fit: cover;
                        margin: 0 30px;

                        @include mobile("380px","992px"){
                            width: 40px;
                            height: 40px;
                        }
                        @include mobile("200px","379px"){
                            width: 35px;
                            height: 35px;
                        }
                    }
                }
            }
            .center{
                padding: 10px;
                border-radius: 10px;
                background-color: rgb(239, 223, 226);

                @include mobile("380px","992px"){
                    font-size: 12px;
                    padding: 5px;
                }
                @include mobile("200px","379px"){
                    font-size: 10px;
                    padding: 3px;
                }
            }
            .bottom{
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                h3{
                    margin-bottom: 5px;

                    @include mobile("380px","576px"){
                        font-size: 14px;
                    }
                    @include mobile("200px","379px"){
                        font-size: 12px;
                    }
                }
                h4{
                    color: gray;

                    @include mobile("380px","576px"){
                        font-size: 13px;
                    }
                    @include mobile("200px","379px"){
                        font-size: 11px;
                    }
                }
            }
        }
    }
}